.has-float-label {
    display: block;
    position: relative
}

.has-float-label label, .has-float-label > span {
    position: absolute;
    cursor: text;
    font-size: 75%;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    top: -.5em;
    left: 20px;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
    cursor: auto;
    pointer-events: none;
}

form .form-group .form-control{
    box-shadow: none !important;
}

.has-float-label label::after, .has-float-label > span::after {
    content: " ";
    display: block;
    position: absolute;
    background: #fff;
    height: 2px;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1
}

.has-float-label .form-control::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus) + * {
    font-size: 13px;
    opacity: .5;
    top: 12px;
    margin-bottom: 0;
    font-weight: 400;
    font-family: 'Roboto',sans-serif;
}

.input-group .has-float-label {
    display: table-cell
}

.input-group .has-float-label .form-control {
    border-radius: .25rem
}

.input-group .has-float-label:not(:last-child), .input-group .has-float-label:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0
}

.input-group .has-float-label:not(:first-child), .input-group .has-float-label:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}